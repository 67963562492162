<template>
    <div class="card box widget-social pt-2 pb-2" :class="componentStyle">
        <widgetTitleBar :moduleData="this.moduleData" :widgetInfo="content" :alarmList="this.alarmList" :menu-items="this.items" @menu-click="($value) => this.toggle($value)"></widgetTitleBar>
        <OverlayPanel
            class="param-overlay"
            ref="alarmOverlay"
            appendTo="body"
            :autoZIndex="true"
            @show="focusElement" 
            :dismissable="true"
            :showCloseIcon="true"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ height: 'auto' }"
        >
            <div v-for="(element) in this.alarmList" :key="element.label">
                <span>🔴 {{ element.label }}</span>
            </div>
        </OverlayPanel>
        <div v-if="isLoading">
            <loader-skeleton :widgetInfo="content"></loader-skeleton>
        </div>
        <Dialog
            v-model:visible="showDiagHeating"
            @show="focusDiagHzg"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Heizungsperioden</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periodsHzg')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true" :activeIndex="diagHeatingPeriod">
                    <TabPanel v-for="(element, index) in this.nodeDataHzg" :key="index">
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataHzg(index, 'Enable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataHzg(index, 'PeriodName').value }}</span>
                            </div>
                        </template>
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <Panel :header="`${this.getDiagDataHzg(index, 'PeriodName').value} - Sollwert Heizung`">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Hier wird der Temperatursollwert für die Heizung eingestellt. Ist die Periode nicht aktiviert, wird die nächste Periode welche aktiv ist verwendet (Zeitfreigaben beachten!).</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col" v-if="this.getDiagDataHzg(index, 'Enable').value">
                                    <div class="p-inputgroup">
                                        <InputNumber id="changingValueField" ref="changingValueField" :min="-100" :max="100"
                                            v-model="this.getDiagDataHzg(index, 'Setpoint').value" autofocus @focus="focusElement"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            inputClass="nice-font inputtext-param text-center text-5xl"  />
                                        <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataHzg(index, 'Setpoint').unit }}</span>
                                    </div>
                                </div>
                                <div class="field col flex justify-content-center ">
                                    <div class="flex align-items-center">
                                        <InputSwitch v-model="this.getDiagDataHzg(index, 'Enable').value" /><span class="ml-2">Periode aktivieren</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataHzg(index, 'PeriodName').value} - Zeiteinstellungen`" class="mt-2" v-if="this.getDiagDataHzg(index, 'Enable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataHzg(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataHzg(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataHzg(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataHzg(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                        <span class="p-float-label mr-2 mt-2">
                                            <InputMask id="transitionLoHi" ref="transitionLoHi" mask="99:99:99"
                                                placeholder="" slotChar="hh:mm:ss"
                                                v-model="this.getDiagDataHzg(index, 'PeriodTransition_LoHi').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="transitionLoHi" class="mr-2">Aufwärmzeit/°C</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataHzg(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataHzg(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataHzg(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataHzg(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                        <span class="p-float-label mt-2">
                                            <InputMask id="transitionHiLo" ref="transitionHiLo" mask="99:99:99"
                                                placeholder="" slotChar="hh:mm:ss"
                                                v-model="this.getDiagDataHzg(index, 'PeriodTransition_HiLo').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="transitionHiLo">Abkühlzeit/°C</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataHzg(index, 'PeriodName').value} - Entfeuchtung (mit Heizung)`" class="mt-2" v-if="this.getDiagDataHzg(index, 'Enable').value">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Bei aktivierter Entfeuchtung und einer Luftfeuchtigkeit mindestens so hoch wie bei Min. eingestellt wird versucht, mit der Heizung die Luftfeuchtigkeit zu senken. Der Bereich Min und Max gibt dabei den Arbeitsbereich für die verbundene Heizung an (siehe Heizungsregler).</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <InputSwitch v-model="this.getDiagDataHzg(index, 'EnableDehumifying').value" /><span class="ml-2">{{ this.getDiagDataHzg(index, 'EnableDehumifying').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataHzg(index, 'EnableDehumifying').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="SetpointDehumifyingMin" ref="SetpointDehumifyingMin" :min="0" :max="100"
                                            v-model="this.getDiagDataHzg(index, 'SetpointDehumifyingMin').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataHzg(index, 'SetpointDehumifyingMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full mb-2" />
                                        <label for="SetpointDehumifyingMin" class="mr-2">{{ this.getDiagDataHzg(index, 'SetpointDehumifyingMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="SetpointDehumifyingMax" ref="SetpointDehumifyingMax" :min="-100" :max="100"
                                            v-model="this.getDiagDataHzg(index, 'SetpointDehumifyingMax').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataHzg(index, 'SetpointDehumifyingMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="SetpointDehumifyingMax" class="mr-2">{{ this.getDiagDataHzg(index, 'SetpointDehumifyingMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataHzg(index, 'PeriodName').value} - Lichtführung`" class="mt-2" v-if="this.getDiagDataHzg(index, 'Enable').value">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-sun" text="<p>Bei aktivierter Lichtführung wird die Solltemperatur der Periode nach unten korrigiert, da damit gerechnet werden kann, dass durch Sonneneinstrahlung Energie in das Gewächshaus gelangt. Die Globalstrahlung muss dabei mindestens den Startwert erreichen. Es wird dann stetig bis zum Ende der Lichtführung der Sollwert mit dem Korrekturwert abgesenkt.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <InputSwitch v-model="this.getDiagDataHzg(index, 'LightAdaption_Enable').value" /><span class="ml-2">{{ this.getDiagDataHzg(index, 'LightAdaption_Enable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataHzg(index, 'LightAdaption_Enable').value">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightAdaptionStart" ref="lightAdaptionStart" :min="0" :max="3000"
                                                v-model="this.getDiagDataHzg(index, 'LightAdaption_Start').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataHzg(index, 'LightAdaption_Start').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionStart" class="mr-2">Lichtführung Start ab</label>
                                        </span>
                                        <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataHzg(index, 'LightAdaption_Start').value, 'toKlx') }} klx</span>
                                        <span class="p-float-label mr-2 mt-2">
                                            <InputNumber id="lightAdaptionValue" ref="lightAdaptionValue" :min="-100" :max="100"
                                                v-model="this.getDiagDataHzg(index, 'LightAdaption_Value').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataHzg(index, 'LightAdaption_Value').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionValue" class="mr-2">Lichtführung Korrekturwert</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="lightAdaptionEnd" ref="lightAdaptionEnd" :min="0" :max="3000"
                                            v-model="this.getDiagDataHzg(index, 'LightAdaption_End').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataHzg(index, 'LightAdaption_End').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="lightAdaptionEnd">Lichtführung Ende ab</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataHzg(index, 'LightAdaption_End').value, 'toKlx') }} klx</span>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataHzg(index, 'PeriodName').value} - Strahlunganhebung`" class="mt-2" v-if="this.getDiagDataHzg(index, 'Enable').value">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-sun" text="<p>Bei aktivierter Strahlungsanhebung wird die über den Tag gemessene Einstrahlungssumme (Globalstrahlung) dazu verwendet, den Sollwert der Periode anzuheben. Befindet sich die heutige Einstrahlungssumme mindestens auf den Startwert, so wird stetig der Sollwert der Heizung bis zum Ende der Einstrahlungssumme mit dem Korrekturwert angehoben.</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <InputSwitch v-model="this.getDiagDataHzg(index, 'LightRaising_Enable').value" /><span class="ml-2">{{ this.getDiagDataHzg(index, 'LightRaising_Enable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataHzg(index, 'LightRaising_Enable').value">
                                <div class="field col-12 text-center">
                                    <p class="fg-lightYellow"><span class="text-sm fg-gray">Strahlungssumme Regelung: </span>{{ this.getModuleValue('OUT_RadiationSum').value + this.getModuleValue('OUT_RadiationSum').unit }}</p>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="LightRaising_Start" ref="LightRaising_Start" :min="0" :max="3000"
                                                v-model="this.getDiagDataHzg(index, 'LightRaising_Start').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataHzg(index, 'LightRaising_Start').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="LightRaising_Start" class="mr-2">{{ this.getDiagDataHzg(index, 'LightRaising_Start').label }}</label>
                                        </span>
                                        <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataHzg(index, 'LightRaising_Start').value, 'toKlx') }} klxh</span>
                                        <span class="p-float-label mr-2 mt-2">
                                            <InputNumber id="LightRaising_Value" ref="LightRaising_Value" :min="-100" :max="100"
                                                v-model="this.getDiagDataHzg(index, 'LightRaising_Value').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataHzg(index, 'LightRaising_Value').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="LightRaising_Value" class="mr-2">{{ this.getDiagDataHzg(index, 'LightRaising_Value').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="LightRaising_End" ref="LightRaising_End" :min="0" :max="3000"
                                            v-model="this.getDiagDataHzg(index, 'LightRaising_End').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataHzg(index, 'LightRaising_End').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="LightRaising_End">{{ this.getDiagDataHzg(index, 'LightRaising_End').label }}</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataHzg(index, 'LightRaising_End').value, 'toKlx') }} klxh</span>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Periodenbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataHzg(index, 'PeriodName'), 'periodsHzg')" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_hzg`" :department="moduleDepartment.key" :exchange="true" format="json" :saveCheck="periodCheckHzg"
                    @diagAbort="diagToggleHeating"
                    @diagSave="saveDiag('periodsHzg')"
                    @import="($value) => {this.nodeDataHzg = $value}"
                    :exportObject="this.nodeDataHzg"
                ></widgetDialogFooter>
            </template>
        </Dialog>
        <Dialog
            v-model:visible="showDiagVents"
            @show="focusDiagLft"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Lüftungsperioden</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('periodsLft')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView v-else :scrollable="true" :activeIndex="diagVentsPeriod">
                    <TabPanel v-for="(element, index) in this.nodeDataLft" :key="index">
                        <!-- <Panel :header="returnParameter(element, 'parameter', 'PeriodName').value + ' - Sollwert'"> -->
                        <template #header>
                            <div class="flex align-items-center gap-2">
                                <span class="white-space-nowrap" :class="this.getDiagDataLft(index, 'Enable').value ? '' : 'line-through'">{{ index+1 }}: {{ this.getDiagDataLft(index, 'PeriodName').value }}</span>
                            </div>
                        </template>
                        <Panel :header="`${this.getDiagDataLft(index, 'PeriodName').value} - Sollwert`">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Hier wird der Temperatursollwert für die Lüftung eingestellt. Ist die Periode nicht aktiviert, wird die nächste Periode welche aktiv ist verwendet (Zeitfreigaben beachten!).</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col" v-if="this.getDiagDataLft(index, 'Enable').value">
                                    <div class="p-inputgroup">
                                        <InputNumber id="changingValueField" ref="changingValueField" :min="-100" :max="100"
                                            v-model="this.getDiagDataLft(index, 'Setpoint').value" autofocus @focus="focusElement"
                                            :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                                            inputClass="nice-font inputtext-param text-center text-5xl"  />
                                        <span class="p-inputgroup-addon nice-font text-3xl">{{ this.getDiagDataLft(index, 'Setpoint').unit }}</span>
                                    </div>
                                </div>
                                <div class="field col flex justify-content-center ">
                                    <div class="flex align-items-center">
                                        <InputSwitch v-model="this.getDiagDataLft(index, 'Enable').value" /><span class="ml-2">Periode aktivieren</span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataLft(index, 'PeriodName').value} - Zeiteinstellungen`" class="mt-2" v-if="this.getDiagDataLft(index, 'Enable').value">
                            <template #icons>
                                <i class="fi fi-rr-time-fast bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataLft(index, 'TimeModeStart').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataLft(index, 'TimeModeStart').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataLft(index, 'TimeSetStart').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
                                            </span>
                                            <span class="p-float-label mr-2">
                                                <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataLft(index, 'TimeSetStart').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeStart" class="mr-2">Periode Beginn</label>
                                            </span>
                                        </div>
                                        <span class="p-float-label mr-2 mt-2">
                                            <InputMask id="transitionLoHi" ref="transitionLoHi" mask="99:99:99"
                                                placeholder="" slotChar="hh:mm:ss"
                                                v-model="this.getDiagDataLft(index, 'PeriodTransition_LoHi').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="transitionLoHi" class="mr-2">Aufwärmzeit/°C</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center mb-2">
                                        <SelectButton v-model="this.getDiagDataLft(index, 'TimeModeEnd').value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                    <div class="field">
                                        <div class="p-inputgroup">
                                            <span v-if="this.getDiagDataLft(index, 'TimeModeEnd').value !== 0" class="p-inputgroup-addon">
                                                <ToggleButton v-model="this.getDiagDataLft(index, 'TimeSetEnd').plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus"/>
                                            </span>
                                            <span class="p-float-label">
                                                <InputMask id="minTimeEnd" ref="minValueTimeEnd" mask="99:99:99" placeholder=""
                                                    slotChar="hh:mm:ss" v-model="this.getDiagDataLft(index, 'TimeSetEnd').value"
                                                    @focus="focusElement" class="p-inputtext-lg w-full" />
                                                <label for="minValueTimeEnd">Periode Ende</label>
                                            </span>
                                        </div>
                                        <span class="p-float-label mt-2">
                                            <InputMask id="transitionHiLo" ref="transitionHiLo" mask="99:99:99"
                                                placeholder="" slotChar="hh:mm:ss"
                                                v-model="this.getDiagDataLft(index, 'PeriodTransition_HiLo').value"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="transitionHiLo">Abkühlzeit/°C</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataLft(index, 'PeriodName').value} - Entfeuchtung (mit Lüftung)`" class="mt-2" v-if="this.getDiagDataLft(index, 'Enable').value">
                            <template #icons>
                                <moduleDialogIconHelp icon="fi fi-rr-temperature-low" text="<p>Bei aktivierter Entfeuchtung und einer Luftfeuchtigkeit mindestens so hoch wie bei Min. eingestellt wird versucht, mit der Lüftung die Luftfeuchtigkeit zu senken. Der Bereich Min und Max gibt dabei den Arbeitsbereich für die verbundene Lüftung an (siehe Lüftungsregler).</p>" ></moduleDialogIconHelp>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <InputSwitch v-model="this.getDiagDataLft(index, 'EnableDehumifying').value" /><span class="ml-2">{{ this.getDiagDataLft(index, 'EnableDehumifying').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataLft(index, 'EnableDehumifying').value">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="SetpointDehumifyingMin" ref="SetpointDehumifyingMin" :min="0" :max="100"
                                            v-model="this.getDiagDataLft(index, 'SetpointDehumifyingMin').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataLft(index, 'SetpointDehumifyingMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full mb-2" />
                                        <label for="SetpointDehumifyingMin" class="mr-2">{{ this.getDiagDataLft(index, 'SetpointDehumifyingMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="SetpointDehumifyingMax" ref="SetpointDehumifyingMax" :min="-100" :max="100"
                                            v-model="this.getDiagDataLft(index, 'SetpointDehumifyingMax').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataLft(index, 'SetpointDehumifyingMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="SetpointDehumifyingMax" class="mr-2">{{ this.getDiagDataLft(index, 'SetpointDehumifyingMax').label }}</label>
                                    </span>
                                </div>
                            </div>
                        </Panel>
                        <Panel :header="`${this.getDiagDataLft(index, 'PeriodName').value} - Lichtführung`" class="mt-2" v-if="this.getDiagDataLft(index, 'Enable').value">
                            <template #icons>
                                <i class="fi fi-rr-sun bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <InputSwitch v-model="this.getDiagDataLft(index, 'LightAdaption_Enable').value" /><span class="ml-2">{{ this.getDiagDataLft(index, 'LightAdaption_Enable').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataLft(index, 'LightAdaption_Enable').value">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightAdaptionStart" ref="lightAdaptionStart" :min="0" :max="3000"
                                                v-model="this.getDiagDataLft(index, 'LightAdaption_Start').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataLft(index, 'LightAdaption_Start').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionStart" class="mr-2">Lichtführung Start ab</label>
                                        </span>
                                        <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataLft(index, 'LightAdaption_Start').value, 'toKlx') }} klx</span>
                                        <span class="p-float-label mr-2 mt-2">
                                            <InputNumber id="lightAdaptionValue" ref="lightAdaptionValue" :min="-100" :max="100"
                                                v-model="this.getDiagDataLft(index, 'LightAdaption_Value').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataLft(index, 'LightAdaption_Value').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionValue" class="mr-2">Lichtführung Korrekturwert</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <span class="p-float-label">
                                        <InputNumber id="lightAdaptionEnd" ref="lightAdaptionEnd" :min="0" :max="3000"
                                            v-model="this.getDiagDataLft(index, 'LightAdaption_End').value" mode="decimal"
                                            :suffix="` ${this.getDiagDataLft(index, 'LightAdaption_End').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="lightAdaptionEnd">Lichtführung Ende ab</label>
                                    </span>
                                    <span class="ml-2 text-sm fg-darkGray">≙ {{ this.calcLux(this.getDiagDataLft(index, 'LightAdaption_End').value, 'toKlx') }} klx</span>
                                </div>
                            </div>
                        </Panel>
                        <div class="formgrid grid p-1">
                            <div class="field col">
                                <div class="flex justify-content-center mt-2">
                                    <Button label="Periodenbezeichnung ändern" @click="toogleNameDiag(this.getDiagDataLft(index, 'PeriodName'), 'periodsLft')" />
                                </div>
                            </div>
                        </div>
                        
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_lft`" :department="moduleDepartment.key" :exchange="true" format="json" :saveCheck="periodCheckLft"
                    @diagAbort="diagToggleVents"
                    @diagSave="saveDiag('periodsLft')"
                    @import="($value) => {this.nodeDataLft = $value}"
                    :exportObject="this.nodeDataLft"
                ></widgetDialogFooter>
            </template>
        </Dialog>
        <!-- <dialog-change-name
            v-if="showDiagName"
            v-model:visible="showDiagName"
            :show-dialog="showDiagName"
            :name-to-change="changeDiagName"
            @entry-save="saveNameDiag"
            @entry-abort="hideNameDiag"
        ></dialog-change-name> -->
        <Dialog 
            v-model:visible="showDiagName"
            :closable="false"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '450px' }"
            :modal="true"
            class="p-fluid"
        >
            <template #header>
                <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
            </template>
            <div @keydown.esc="hideNameDiag" @keydown.enter="saveNameDiag(changeDiagName)">
                <Panel>
                    <div class="grid p-fluid">
                        <div class="col-12 pl-5 pr-5">
                            <span class="p-float-label mr-2">
                                <InputText id="periodName" ref="periodName" v-model="changeDiagName.value" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                                <label for="periodName" class="mr-2">Bezeichnung</label>
                            </span>
                        </div>
                    </div>
                </Panel>
            </div>
            <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideNameDiag" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveNameDiag(changeDiagName)" />
            </template>
        </Dialog>
        <Dialog
            v-model:visible="showDiagMisc"
            @show="focusElement"
            @hide="hideOP"
            :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
            :style="{ width: '700px' }"
        >
            <template #header>
                <h5><i class="pi pi-cog">&nbsp;</i><span class="subtext mt-1">{{ moduleDepartment.key }} {{ moduleDepartment.label }} ⚡ Allgemeine Einstellungen</span></h5>
            </template>
            <div v-if="saveWait" class="flex justify-content-center flex-wrap">
                <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="4"
                fill="var(--red-600)"
                animationDuration="1s"
                />
            </div>
            <div v-else @keydown.esc="hideOP" @keydown.enter="saveDiag('misc')">
                <loader v-if="dialogLoading" :style="'box'"></loader>
                <TabView :scrollable="true">
                    <TabPanel header="Alarme Temperatur">
                        <Panel header="Alarmeinstellungen Raumtemperatur-Minimum Temperatur">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRtMin" ref="alarmRtMin" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('alarmRtMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRtMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRtMin" class="mr-2">{{ getDiagDataCfg('alarmRtMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRtMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Raumtemperatur-Maximum Temperatur" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRtMax" ref="alarmRtMax" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('alarmRtMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRtMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRtMax" class="mr-2">{{ getDiagDataCfg('alarmRtMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRtMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Raumtemperatur-Differenz Temperatur" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRtDiff" ref="alarmRtDiff" :min="-50" :max="100"
                                            v-model="getDiagDataCfg('alarmRtDiff').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRtDiff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRtDiff" class="mr-2">{{ getDiagDataCfg('alarmRtDiff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRtDiffPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarme Feuchte">
                        <Panel header="Alarmeinstellungen Raumfeuchtigkeit-Minimum Feuchtigkeit">
                            <template #icons>
                                <i class="fi fi-rr-temperature-low bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRhMin" ref="alarmRhMin" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmRhMin').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRhMin').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRhMin" class="mr-2">{{ getDiagDataCfg('alarmRhMin').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRhMinPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Raumfeuchtigkeit-Maximum Feuchtigkeit" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRhMax" ref="alarmRhMax" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmRhMax').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRhMax').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRhMax" class="mr-2">{{ getDiagDataCfg('alarmRhMax').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRhMaxPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen Wasserbehälter Messkasten" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-temperature-high bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <span class="p-float-label mr-2">
                                        <InputNumber id="alarmRhWaterDiff" ref="alarmRhWaterDiff" :min="0" :max="100"
                                            v-model="getDiagDataCfg('alarmRhWaterDiff').value" mode="decimal"
                                            :suffix="` ${getDiagDataCfg('alarmRhWaterDiff').unit}`"
                                            :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                            @focus="focusElement" class="p-inputtext-lg w-full" />
                                        <label for="alarmRhWaterDiff" class="mr-2">{{ getDiagDataCfg('alarmRhWaterDiff').label }}</label>
                                    </span>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmRhWaterPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sonderparameter" v-if="isMaster">
                        <Panel header="Strahlung - Strahlungssumme Feintuning" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-0">
                                            <p class="text-sm">{{ this.getDiagDataCfg('lightRaising_Set').label }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-0">
                                            <p class="text-sm">{{ this.getDiagDataCfg('lightRaising_Reset').label }}</p>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagDataCfg('lightRaising_Set').value" :options="getDayPhase" optionLabel="label" optionValue="value" placeholder="Tagesphase wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="flex justify-content-center">
                                        <div class="flex align-items-center mt-2 mb-2">
                                            <Dropdown v-model="this.getDiagDataCfg('lightRaising_Reset').value" :options="getDayPhase" optionLabel="label" optionValue="value" placeholder="Tagesphase wählen" class="w-full md:w-14rem"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Sensoreinstellungen" v-if="isMaster">
                        <Panel header="Feuchtemessung" class="mt-0">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field col flex justify-content-center">
                                        <div class="flex align-items-center">
                                            <InputSwitch v-model="this.getDiagDataCfg('enableHumidity').value" /><span class="ml-2">{{ this.getDiagDataCfg('enableHumidity').label }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1" v-if="this.getDiagDataCfg('enableHumidity').value">
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="OUT_RT" ref="OUT_RT"
                                                v-model="this.getModuleValue('OUT_RT').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('OUT_RT').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="OUT_RT" class="mr-2">{{ this.getModuleValue('OUT_RT').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="OUT_RTWET" ref="OUT_RTWET"
                                                v-model="this.getModuleValue('OUT_RTWET').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('OUT_RTWET').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="OUT_RTWET" class="mr-2">{{ this.getModuleValue('OUT_RTWET').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Filtereinstellungen" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-settings bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempFilterTime" ref="tempFilterTime" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('tempFilterTime').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempFilterTime').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempFilterTime" class="mr-2">{{ this.getDiagDataCfg('tempFilterTime').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightAdaptionUp" ref="lightAdaptionUp" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('lightAdaptionUp').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('lightAdaptionUp').unit}`"
                                                :minFractionDigits="3" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionUp" class="mr-2">{{ this.getDiagDataCfg('lightAdaptionUp').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="lightAdaptionDn" ref="lightAdaptionDn" :min="0" :max="100000"
                                                v-model="this.getDiagDataCfg('lightAdaptionDn').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('lightAdaptionDn').unit}`"
                                                :minFractionDigits="3" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="lightAdaptionDn" class="mr-2">{{ this.getDiagDataCfg('lightAdaptionDn').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung: Dry Bulb" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensInMin" ref="tempSensInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('tempSensInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensOutMin" ref="tempSensOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('tempSensOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('tempSensOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensInMax" ref="tempSensInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('tempSensInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensOutMax" ref="tempSensOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('tempSensOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('tempSensOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensOffset" ref="tempSensOffset" :min="-100" :max="100"
                                                v-model="this.getDiagDataCfg('tempSensOffset').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempSensOffset').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensOffset" class="mr-2">{{ this.getDiagDataCfg('tempSensOffset').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="IN_RT" ref="IN_RT"
                                                v-model="this.getModuleValue('IN_RT').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('IN_RT').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="IN_RT" class="mr-2">{{ this.getModuleValue('IN_RT').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="OUT_RT" ref="OUT_RT"
                                                v-model="this.getModuleValue('OUT_RT').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('OUT_RT').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="OUT_RT" class="mr-2">{{ this.getModuleValue('OUT_RT').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Sensorskalierung: Wet Bulb" class="mt-3">
                            <template #icons>
                                <i class="fi fi-rr-cog bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensWetInMin" ref="tempSensWetInMin" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('tempSensWetInMin').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensWetInMin" class="mr-2">Sensor IN Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensWetOutMin" ref="tempSensWetOutMin" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('tempSensWetOutMin').value"
                                                :suffix="` ${this.getDiagDataCfg('tempSensWetOutMin').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensWetOutMin" class="mr-2">Sensor OUT Untergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensWetInMax" ref="tempSensWetInMax" :min="0" :max="32767"
                                                v-model="this.getDiagDataCfg('tempSensWetInMax').value" mode="decimal"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensWetInMax" class="mr-2">Sensor IN Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensWetOutMax" ref="tempSensWetOutMax" :min="-1000" :max="1000"
                                                v-model="this.getDiagDataCfg('tempSensWetOutMax').value"
                                                :suffix="` ${this.getDiagDataCfg('tempSensWetOutMax').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensWetOutMax" class="mr-2">Sensor OUT Obergrenze</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="tempSensWetOffset" ref="tempSensWetOffset" :min="-100" :max="100"
                                                v-model="this.getDiagDataCfg('tempSensWetOffset').value" mode="decimal"
                                                :suffix="` ${this.getDiagDataCfg('tempSensWetOffset').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                @focus="focusElement" class="p-inputtext-lg w-full" />
                                            <label for="tempSensWetOffset" class="mr-2">{{ this.getDiagDataCfg('tempSensWetOffset').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid p-1">
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="IN_RTWET" ref="IN_RTWET"
                                                v-model="this.getModuleValue('IN_RTWET').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('IN_RTWET').unit}`"
                                                :minFractionDigits="0" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="IN_RTWET" class="mr-2">{{ this.getModuleValue('IN_RTWET').label }}</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field mt-2">
                                        <span class="p-float-label mr-2">
                                            <InputNumber id="OUT_RTWET" ref="OUT_RTWET"
                                                v-model="this.getModuleValue('OUT_RTWET').value" mode="decimal"
                                                :suffix="` ${this.getModuleValue('OUT_RTWET').unit}`"
                                                :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                class="p-inputtext-lg w-full" :disabled="true" />
                                            <label for="OUT_RTWET" class="mr-2">{{ this.getModuleValue('OUT_RTWET').label }}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Alarmeinstellungen - Sensorstörung" class="mt-2">
                            <template #icons>
                                <i class="fi fi-rr-alarm-clock bg-bespin fg-lightYellow p-panel-header-icon text-xl"></i>
                            </template>
                            <div class="formgrid grid p-1">
                                <div class="field col-3">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputMask id="alarmSensorDelay" ref="alarmSensorDelay" mask="99:99:99"
                                                    placeholder="" slotChar="hh:mm:ss"
                                                    v-model="this.getDiagDataCfg('alarmSensorDelay').value"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmSensorDelay" class="mr-2">{{ this.getDiagDataCfg('alarmSensorDelay').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-3">
                                    <span class="p-float-label">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label mr-2">
                                                <InputNumber id="alarmSensorDiff" ref="alarmSensorDiff" :min="0" :max="100000"
                                                    v-model="this.getDiagDataCfg('alarmSensorDiff').value" mode="decimal"
                                                    :suffix="` ${this.getDiagDataCfg('alarmSensorDiff').unit}`"
                                                    :minFractionDigits="1" :useGrouping="false" locale="de-DE"
                                                    @focus="focusElement" class="p-inputtext-lg" />
                                                <label for="alarmSensorDiff" class="mr-2">{{ this.getDiagDataCfg('alarmSensorDiff').label }}</label>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                                <div class="field col-6">
                                    <div class="flex justify-content-center">
                                        <SelectButton v-model="getDiagDataCfg('alarmSensorPrior').value" :options="this.$store.getters.getAlarmPrior" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name">
                                            <template #option="slotProps">
                                                <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
                                            </template>
                                        </SelectButton>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </TabPanel>
                    <TabPanel header="Alarmausgang" v-if="isMaster">
                        <widgetAlarmOutput :value="this.getDiagDataCfg('alarmAssignedOutput')" @setOutput="($value) => {this.getDiagDataCfg('alarmAssignedOutput').value = $value}"></widgetAlarmOutput>
                    </TabPanel>
                </TabView>
            </div>
            <template #footer>
                <widgetDialogFooter :widgetName="`${this.$options.name}_misc`" :department="moduleDepartment.key" :exchange="true" format="json"
                    @diagAbort="diagToggleMisc"
                    @diagSave="saveDiag('misc')"
                    @import="($value) => {this.nodeDataCfg = $value}"
                    :exportObject="this.nodeDataCfg"
                ></widgetDialogFooter>
            </template>
        </Dialog>

        <div v-if="!isLoading">
            <div class="flex justify-content-between align-items-center p-0 flex-wrap">
                <div class="flex justify-content-start align-items-center p-0">
                    <moduleIconEnat :icon="moduleData.metadata.icon" :alarmStatus="alarmStatus" :alarmQueue="alarmQueue" @toogleAlarmOverlay="toogleAlarmOverlay"></moduleIconEnat>
                    <div class="hidden sm:flex mt-1 ml-2" v-if="this.getModuleValue('CFG.enableHumidity').value === true">
                        <widget-mini-progress label="RH" :value="getModuleValue('OUT_Humidity')"></widget-mini-progress>
                        <widget-mini-progress label="RH" :value="getModuleValue('OUT_HumidityAbs')"></widget-mini-progress>
                        <widget-mini-progress label="Defizit" :value="getModuleValue('OUT_HumidityDef')" :max="50"></widget-mini-progress>
                    </div>
                    <div class="flex flex-column mt-3">
                        
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center p-0">
                    <widget-header :value="this.getModuleValue('OUT_RT')" :moduleData="this.moduleData" @showChart="showChart('value1')" :fgColor="fontColor" />
                </div>
            </div>

            <div class="stats flex mt-1">
                <!-- <div class="left flex flex-column"> -->
                <div class="left">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mb-2">
                            <button-enat title="Heizungsperioden" subtitle="Einstellungen Heizungstemperaturen" icon="fi fi-rr-heat" fgColor="yellow" bgColor="crimson"
                                :badge="periodIndexHzg + 1" @click="toggleOP($event, 'periodsHeating')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Periode" :value="getModuleValue('AktPeriodeHzg.PeriodName').value" :moduleData="moduleData" icon="fi fi-rr-time-twenty-four" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Periode-Soll" :value="getModuleValueStr('AktPeriodeHzg.Setpoint')" :moduleData="moduleData" icon="fi fi-rr-snowflake" class="cursor-pointer" @click="showChart('tempSetHzg')" />
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="berechnet" :value="getModuleValueStr('AktSollwertHzg')" :moduleData="moduleData" icon="fi fi-rr-thermometer-half" class="cursor-pointer" @click="showChart('tempSetHzgAkt')" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="akt.Soll" :value="getModuleValueStr('AktSollwertHzgLicht')" :moduleData="moduleData" icon="fi fi-rr-temperature-hot" class="cursor-pointer" @click="showChart('tempSetHzgLight')" />
                        </div>
                    </div>
                    <textbox-enat title="Heizung Status" :value="statusStrHzg" :moduleData="moduleData" icon="fi fi-rr-comment-alt" class="mt-2"/>
                </div>
                <div class="right flex flex-column">
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mb-2">
                            <button-enat title="Lüftungsperioden" subtitle="Einstellungen Lüftungstemperaturen" icon="fi fi-rr-snowflake" fgColor="yellow" bgColor="darkGreen"
                                :badge="periodIndexLft + 1" @click="toggleOP($event, 'periodsVents')" >
                            </button-enat>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Periode" :value="getModuleValue('AktPeriodeLft.PeriodName').value" :moduleData="moduleData" icon="fi fi-rr-time-twenty-four" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="Periode-Soll" :value="getModuleValueStr('AktPeriodeLft.Setpoint')" :moduleData="moduleData" icon="fi fi-rr-snowflake" class="cursor-pointer" @click="showChart('tempSetLft')" />
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="berechnet" :value="getModuleValueStr('AktSollwertLft')" :moduleData="moduleData" icon="fi fi-rr-thermometer-half" class="cursor-pointer" @click="showChart('tempSetLftAkt')" />
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-1 mt-1">
                            <valuebox-enat title="akt.Soll" :value="getModuleValueStr('AktSollwertLftLicht')" :moduleData="moduleData" icon="fi fi-rr-temperature-hot" class="cursor-pointer" @click="showChart('tempSetLftLight')" />
                        </div>
                    </div>
                    <textbox-enat title="Lüftung Status" :value="statusStrLft" :moduleData="moduleData" icon="fi fi-rr-comment-alt" class="mt-2"/>
                </div>
            </div>
            <div class="flex justify-content-center align-items-center p-0 flex-wrap">
                <div class="flex-1 align-items-center justify-content-center mt-2 mb-2">
                    <button-enat title="Allgemeine Einstellungen" subtitle="Alarme, Meldungen" icon="fi fi-rr-settings" fgColor="yellow" bgColor="darkSteel"
                       @click="toggleOP($event, 'misc')" >
                    </button-enat>
                </div>
            </div>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <dialog-chart-single :icon="getModuleValue('OUT_RT').icon" :node="getModuleValue('OUT_RT')" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_Humidity').icon" :node="getModuleValue('OUT_Humidity')" v-bind:show="showChartRhRel" @chart-close="showChartRhRel = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_HumidityAbs').icon" :node="getModuleValue('OUT_HumidityAbs')" v-bind:show="showChartRhAbs" @chart-close="showChartRhAbs = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('OUT_HumidityDef').icon" :node="getModuleValue('OUT_HumidityDef')" v-bind:show="showChartRhDefizit" @chart-close="showChartRhDefizit = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('SollwertHzg').icon" :node="getModuleValue('SollwertHzg')" v-bind:show="showChartTempSetHzg" @chart-close="showChartTempSetHzg = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('AktSollwertHzg').icon" :node="getModuleValue('AktSollwertHzg')" v-bind:show="showChartTempSetHzgAkt" @chart-close="showChartTempSetHzgAkt = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('AktSollwertHzgLicht').icon" :node="getModuleValue('AktSollwertHzgLicht')" v-bind:show="showChartTempSetHzgLight" @chart-close="showChartTempSetHzgLight = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('SollwertLft').icon" :node="getModuleValue('SollwertLft')" v-bind:show="showChartTempSetLft" @chart-close="showChartTempSetLft = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('AktSollwertLft').icon" :node="getModuleValue('AktSollwertLft')" v-bind:show="showChartTempSetLftAkt" @chart-close="showChartTempSetLftAkt = false"></dialog-chart-single>
        <dialog-chart-single :icon="getModuleValue('AktSollwertLftLicht').icon" :node="getModuleValue('AktSollwertLftLicht')" v-bind:show="showChartTempSetLftLight" @chart-close="showChartTempSetLftLight = false"></dialog-chart-single>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
import helpers from '@/helpers';
import { parseNode, setOpcValueToast } from '@/helpers';
import SocketioService from '@/services/socketioService.js';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
import widgetMiniProgress from '@/components/fragments/widgetMiniProgress.vue';
import widgetAlarmOutput from '@/components/fragments/widgetAlarmOutput.vue';
import widgetDialogFooter from '@/components/fragments/widgetDialogFooter.vue';
import moduleIconEnat from '@/components/fragments/moduleIconEnat.vue';
import moduleDialogIconHelp from '@/components/fragments/moduleDialogIconHelp.vue';
import widgetTitleBar from '@/components/fragments/widgetTitleBar.vue';
// import _ from "lodash";
// import Widget_rowValues2 from './fragments/widget_rowValues2.vue';
// import dialogChangeName from '@/components/dialog/changeName.vue';

export default defineComponent({
    name: 'widgetGhTempController',
    setup() { },
    components: {
        dialogChartSingle,
        widgetMiniProgress,
        widgetAlarmOutput,
        widgetDialogFooter,
        moduleIconEnat,
        moduleDialogIconHelp,
        widgetTitleBar,
        // dialogChangeName,
        // Widget_rowValues2
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        createdLayout: {
            type: Boolean,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        createdLayout() {
            this.watchCreatedLayout();
        },
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
        // nodeEntry: {
        //     handler: function(entry) {
        //         // this.filterParameters();
        //         // if (Array.isArray(entries) && entries.length > 0) {
        //         //     this.loading = false;
        //         // } else {
        //         //     this.loading = true;
        //         // }
        //     },
        //     deep: true
        // }
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getModuleFetched: 'opcua/getModuleFetched',
            getNode: 'opcua/getNode',
            isMaster: 'auth/isMaster',
            getDayPhase: 'types/getDayPhase',
        }),
        isLoading: function() {
            if (!this.moduleData || !this.getModuleFetched(this.moduleHash) || this.loading) return true;
            return false;
        },
        
        connectedModule: function () {
            if (this.content.data.module) {
                return this.content.data.module;
            }
            return null;
        },
        moduleData: function () {
            if (this.connectedModule) {
                const module = this.getModule(this.connectedModule);
                if (module) return module;
            }
            return null;
        },
        moduleHash: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.source.server !== null && this.moduleData.source.identifier !== null) {
                    return `${this.moduleData.source.server}@${this.moduleData.source.identifier}`
                }
            }
            return null;
        },
        moduleDepartment: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.department !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.department);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        moduleCategory: function () {
            if (this.moduleData !== null) {
                if (this.moduleData.metadata.category !== null) {
                    return this.getOneDepartment(this.moduleData.metadata.category);
                }
            }
            return { key: store.getters.getNoval, label: store.getters.getNoval };
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else if (this.moduleData !== null && this.moduleData.metadata.label) {
                return this.moduleData.metadata.label;
            } else {
                return store.getters.getNoval;
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        componentStyle: function () {
            if (this.content.style && this.content.style !== 'standard') {
                let background = `bg-${this.content.style.background}`;
                if (this.alarmStatus > 0) background = 'alarm';
                return `box ptrn-${this.content.style.pattern} ${background} fg-${this.content.style.foreground}`;
            } else {
                return '';
            }
        },
        fontColor: function () {
            if (this.content.style && this.content.style.foreground) {
                return `fg-${this.content.style.foreground}`;
            }
            return 'gray';
        },
        alarmEntriesCount: function () {
            if (this.getModuleValue(`AlarmEntriesCount`).value > 0) {
                return this.getModuleValue(`AlarmEntriesCount`).value;
            } else {
                return 0;
            }
        },
        alarmStatus: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value <= 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmQueue: function () {
            let alarmCount = this.alarmEntriesCount;
            for (let i=0; i<this.alarmEntriesCount; i++) {
                // console.log(this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`));
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value === 0) {
                    alarmCount -= 1;
                }
            }
            return alarmCount;
        },
        alarmList: function () {
            let activeAlarms = [];
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value !== 0) {
                    const alarm = {
                        label: this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value,
                        state: this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value
                    }
                    activeAlarms.push(alarm);
                }
            }
            return activeAlarms;
        },
        alarmTicker: function () {
            let alarmStr = '';
            for (let i=0; i<this.alarmEntriesCount; i++) {
                if (this.getModuleValue(`AlarmEntries[${i}].OUT_AlarmState`).value > 0) {
                    alarmStr += `🔴 ${this.getModuleValue(`AlarmEntries[${i}].CFG.alarmLabel`).value} `;
                }
            }
            return alarmStr;
        },
        alarmStatus1: function () {
            // if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
            //     let iCol;
            //     const index = findIndexByDisplayName(this.nodeEntry.children, 'AlarmStatus');
            //     if (this.nodeEntry.children[index].value.length > 0) {
            //         iCol = ' alarm';
            //     } else {
            //         iCol = '';
            //     }
            //     return iCol;
            // } else {
            //     return 'mif-blocked';
            // }
            return '';
        },
        periodIndexHzg: function () {
            return this.getModuleValue('AktPeriodeHzg.PeriodIndex').value;
        },
        periodCheckHzg: function () {
            if (this.nodeDataHzg.length > 0) {
                let cnt = 0;
                this.nodeDataHzg.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'Enable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
        periodIndexLft: function () {
            return this.getModuleValue('AktPeriodeLft.PeriodIndex').value;
        },
        periodCheckLft: function () {
            if (this.nodeDataLft.length > 0) {
                let cnt = 0;
                this.nodeDataLft.forEach((period) => {
                    const result = period.find(entry => entry.displayName === 'Enable');
                    if (result) {
                        if (result.value === true) cnt+=1;
                    }
                });
                if (cnt > 0) return false;
            }
            return true;
        },
        statusStrHzg: function () {
            if (this.getModuleValue('StatusStrHzg').value && this.getModuleValue('OUT_RT') && this.getModuleValue('AktPeriodeHzg.Setpoint')) {
                if (this.getModuleValue('StatusStrHzg').value === 'Raumsollwert erreicht') {
                    if (this.getModuleValue('OUT_RT').value >= this.getModuleValue('AktPeriodeHzg.Setpoint').value) {
                        return "Heizung Standby";
                    } else if (this.getModuleValue('OUT_RT').value < this.getModuleValue('AktPeriodeHzg.Setpoint').value) {
                        return "Heizung Bedarf";
                    }
                }
            }
            return this.getModuleValue('StatusStrHzg').value;
        },
        statusStrLft: function () {
            if (this.getModuleValue('StatusStrLft').value && this.getModuleValue('OUT_RT') && this.getModuleValue('AktPeriodeLft.Setpoint')) {
                if (this.getModuleValue('StatusStrLft').value === 'Raumsollwert erreicht') {
                    if (this.getModuleValue('OUT_RT').value >= this.getModuleValue('AktPeriodeLft.Setpoint').value) {
                        return "Lüftung Bedarf";
                    } else if (this.getModuleValue('OUT_RT').value < this.getModuleValue('AktPeriodeLft.Setpoint').value) {
                        return "Lüftung Standby";
                    }
                }
            }
            return this.getModuleValue('StatusStrLft').value;
        },
    },
    data() {
        return {
            loading: false,
            showChart1: false,
            showChartRhRel: false,
            showChartRhAbs: false,
            showChartRhDefizit: false,
            showChartTempSetHzg: false,
            showChartTempSetHzgAkt: false,
            showChartTempSetHzgLight: false,
            showChartTempSetLft: false,
            showChartTempSetLftAkt: false,
            showChartTempSetLftLight: false,
            dialogLoading: false,
            entryDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            currentGrp: {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                periods: [],
                controller: {},
                activeIndex: null,
            },
            node: null,
            nodeDataHzg: [],
            nodeDataHzgOld: [],
            nodeDataLft: [],
            nodeDataLftOld: [],
            nodeDataCfg: [],
            nodeDataCfgOld: [],
            saveWait: false,
            currentGrp2: null,
            showDiagHeating: false,
            diagHeatingPeriod: 0,
            showDiagVents: false,
            diagVentsPeriod: 0,
            showDiagName: false,
            showDiagMisc: false,
            changeDiagName: null,
            changeDiagCategory: null,
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Heizungsperioden',
                    icon: 'fi fi-rr-heat',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periodsHeating');
                    },
                },
                {
                    label: 'Lüftungsperioden',
                    icon: 'fi fi-rr-snowflake',
                    command: () => {
                        // this.editEntry(this.content);
                        this.toggleOP(null, 'periodsVents');
                    },
                },
                {
                    label: 'Einstellungen',
                    icon: 'fi fi-rr-settings',
                    command: () => {
                        // this.editValve(this.content);
                        this.toggleOP(null, 'misc');
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Widget Config',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.checkEditMode();
        if (!this.getModuleFetched(this.moduleHash)) {
            this.loadModule(this.connectedModule).then((module) => {
                const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                    if (!err && response) {
                        if (Array.isArray(response) && response.length > 0) {
                            this.setNodesFull(response);
                        }
                    }
                }).then(() => { 
                    if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                    this.setModuleFetched(this.moduleHash);
                    this.loading = false;
                });
            });
        } else {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            loadModule: 'opcua/loadModule',
            setNodesFull: 'opcua/setNodesFull',
            setModuleFetched: 'opcua/setModuleFetched',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        getModuleValue(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            return this.getNode(key);
        },
        getModuleValueStr(schemeEntry) {
            const key = `${this.moduleHash}.${schemeEntry}`;
            const node = this.getNode(key);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        // getDiagData(key) {
        //     if (this.nodeData.length > 0) {
        //         const result = this.nodeData.find(entry => entry.key2 === key);
        //         return result;
        //     }
        //     return null;
        // },
        getDiagDataHzg(index, displayName) {
            if (this.nodeDataHzg.length > 0 && index >= 0) {
                const result = this.nodeDataHzg[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataLft(index, displayName) {
            if (this.nodeDataLft.length > 0 && index >= 0) {
                const result = this.nodeDataLft[index].find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        getDiagDataCfg(displayName) {
            if (this.nodeDataCfg.length > 0 && displayName) {
                const result = this.nodeDataCfg.find(entry => entry.displayName === displayName);
                return result;
            }
            return null;
        },
        watchCreatedLayout() {
            // this.loading = true;
        },
        watchLayout() {
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        clearVals() {
            this.nodeDataHzg = [];
            this.nodeDataHzgOld = [];
            this.nodeDataLft = [];
            this.nodeDataLftOld = [];
            this.saveWait = false;
            this.dialogLoading = false;
        },
        resetCurrentGrp() {
            this.currentGrp = {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
                activeIndex: null,
            }
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        findKey(el, str) {
            const ret = helpers.findByKey(el, str);
            if (ret !== false) {
                return ret;
            }
            return null;
        },
        updateMap() {
            // this.node = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    this.node.set(key, {...child, value: child.value});
                }
            }
        },
        currentMap() {
            let map = new Map();
            if (this.node && this.node.size > 0) {
                for (let [key, element] of this.node) {
                    const child = store.getters.getMapValue(element.uuid);
                    map.set(key, {...child, value: child.value});
                }
                return map;
            }
            return null;
        },
        returnChild(childStr) {
            // if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
            //     return helpers.returnChild(this.nodeEntry, childStr);
            // } else {
            //     return null;
            // }
            if (typeof childStr == 'string' && this.nodeEntry !== undefined) {
                const parameter = this.node.get(childStr);
                if (parameter) {
                    const child = store.getters.getMapValue(parameter.uuid);
                    // const child = { ...this.returnChild('Temperatur') };
                    return child;
                } else {
                    return '0';
                }
            } else {
                return null;
            }
        },
        returnParameter(node, key, parameter) {
            const path = helpers.findPath(node, key, parameter);
            let obj = helpers.getObjectFromPath(node, path);
            return obj;
        },
        returnParameterValue(node, key, parameter, unit) {
            // let retVal = null;
            const path = helpers.findPath(node, key, parameter);
            let obj = helpers.getObjectFromPath(node, path);
            // if (obj !== null && obj.value !== null) {
            //     if (obj.factor > 1 ) {
            //         retVal = obj.value / obj.factor;
            //     } else {
            //         retVal = obj.value;
            //     }
            //     if(typeof retVal == 'number') {
            //         const digits = helpers.getDigits(obj.factor)-1;
            //         if(digits > 0) {
            //             retVal = retVal.toFixed(digits);
            //         }
            //     }
            // }
            // if (unit) {
            //     return retVal + obj.unit;
            // } else {
            //     return retVal;
            // }
            if (obj) {
                if (unit) {
                    return obj.value + obj.unit;
                } else {
                    return obj.value;
                }
            } else {
                return null;
            }
        },
        returnNodeValue(param) {
            const parameter = this.node.get(param);
            const child = store.getters.getMapValue(parameter.uuid);
            return child;
        },
        toogleNameDiag(name, category) {
            this.changeDiagName = JSON.parse(JSON.stringify(name));
            this.changeDiagCategory = category;
            this.showDiagName = true;
        },
        toogleAlarmOverlay(event) {
            this.$refs.alarmOverlay.toggle(event);
        },
        hideNameDiag() {
            this.changeDiagName = null;
            this.changeDiagCategory = null;
            this.showDiagName = false;
        },
        saveNameDiag(node) {
            console.log(node);
            if (node.child0) {
                const child = JSON.parse(node.child0);
                if (child.index >= 0) {
                    console.log(child.index)
                    let obj2 = null;
                    if (this.changeDiagCategory === 'periodsHzg') obj2 = this.nodeDataHzg[child.index].find((item) => item.identifier === node.identifier);
                    else if (this.changeDiagCategory === 'periodsLft') obj2 = this.nodeDataLft[child.index].find((item) => item.identifier === node.identifier);
                    if (obj2) {
                        if (typeof this.changeDiagName.value === 'string' && this.changeDiagName.value.length > 0) {
                            obj2.value = this.changeDiagName.value;
                        }
                        console.log(obj2);
                    }
                }
            }
            // if (typeof this.changeDiagName.value == 'string' && this.changeDiagName.value !== "") {
            //     // const child = helpers.findByKey(this.currentGrp2, name.key);
            //     // child.value = this.changeDiagName.value;
            //     const child = this.node.get(name.key);
            //     child.value = this.changeDiagName.value;
            //     console.log(">>> CHANGE NAME:", child);
            // }
            this.hideNameDiag();
        },
        getParameter(lvl1, lvl2, index) {
            let parameter = null;
            if (!lvl1) return false;
            if (!lvl2) lvl2 = '';
            if (!isNaN(index)) {
                parameter = this.node.get(lvl1 + '[' + index + '].' + lvl2);
            } else {
                parameter = this.node.get(lvl1 + lvl2);
            }
            return parameter;
        },
        toggleOP(event, grp) {
            if (grp === 'periodsHeating') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.Perioden_Heizung*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child0) {
                                    const child = JSON.parse(entry.child0)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataHzg[child.index])) {
                                            this.nodeDataHzg[child.index] = [];
                                        }
                                        this.nodeDataHzg[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataHzgOld = JSON.parse(JSON.stringify(this.nodeDataHzg));
                            console.log(this.nodeDataHzg);
                            console.log(this.nodeDataHzgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagHeating = true;
                });        
            } else if (grp === 'periodsVents') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.Perioden_Lueftung*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            let tmpNodes = [];
                            tmpNodes = JSON.parse(JSON.stringify(response));
                            tmpNodes.forEach((entry) => {
                                if (entry.child0) {
                                    const child = JSON.parse(entry.child0)
                                    if (child.index >= 0) {
                                        if (!Array.isArray(this.nodeDataLft[child.index])) {
                                            this.nodeDataLft[child.index] = [];
                                        }
                                        this.nodeDataLft[child.index].push(entry);
                                    }
                                }
                                // console.log(entry);
                            });
                            this.nodeDataLftOld = JSON.parse(JSON.stringify(this.nodeDataLft));
                            console.log(this.nodeDataLft);
                            console.log(this.nodeDataLftOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                    }
                }).then(() => {
                    this.showDiagVents = true;
                }); 
            } else if (grp === 'misc') {
                SocketioService.getRedisNodesParamScaled(`${this.moduleHash}.CFG*`, (err, response) => {
                    if (err || !response) {
                        console.log(`[ENATWIDGET] error fetching params: ${err}`);
                    }
                    else {
                        if (Array.isArray(response) && response.length > 0) {
                            this.nodeDataCfg = JSON.parse(JSON.stringify(response));
                            this.nodeDataCfgOld = JSON.parse(JSON.stringify(response));
                        }
                            console.log(this.nodeDataCfg);
                            console.log(this.nodeDataCfgOld);
                            // console.log(this.getDiagDataHzg(0, 'PeriodName'));
                        }
                }).then(() => {
                    this.showDiagMisc = true;
                }); 
            }
        },
        diagToggleHeating() {
            if (this.showDiagHeating) {
                this.showDiagHeating = false;
            } else {
                this.showDiagHeating = true;
            }
            this.hideOP();
        },
        diagToggleVents() {
            if (this.showDiagVents) {
                this.showDiagVents = false;
            } else {
                this.showDiagVents = true;
            }
            this.hideOP();
        },
        diagToggleMisc() {
            if (this.showDiagMisc) {
                this.showDiagMisc = false;
            } else {
                this.showDiagMisc = true;
            }
            this.hideOP();
        },
        hideOP() {
            if (!this.showDiagHeating) {
                this.nodeDataHzg = [];
                this.nodeDataHzgOld = [];
                this.diagHeatingPeriod = 0;
            }
            
            if (!this.showDiagVents) {
                this.nodeDataLft = [];
                this.nodeDataLftOld = [];
                this.diagVentsPeriod = 0;
            }
            if (!this.showDiagMisc) {
                this.nodeDataCfg = [];
                this.nodeDataCfgOld = [];
            }
            this.dialogLoading = false;
            this.saveWait = false;
        },
        saveDiag(diag) {
            if (diag) {
                const saveArray = [];
                this.dialogLoading = true;
                this.saveWait = true;

                if (diag === 'periodsHzg' && !this.periodCheckHzg) {
                    console.log(this.nodeDataHzg);
                    console.log(this.nodeDataHzgOld);
                    const flatHzg = this.nodeDataHzg.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataHzgOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'periodsLft' && !this.periodCheckLft) {
                    console.log(this.nodeDataLft);
                    console.log(this.nodeDataLftOld);
                    const flatHzg = this.nodeDataLft.flatMap(innerArr => innerArr);
                    const flatHzgOld = this.nodeDataLftOld.flatMap(innerArr => innerArr);
                    console.log(flatHzg);
                    console.log(flatHzgOld);

                    flatHzg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = flatHzgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                } else if (diag === 'misc') {
                    console.log(this.nodeDataCfg);
                    console.log(this.nodeDataCfgOld);
                    this.nodeDataCfg.forEach((obj1) => {
                    // Find the corresponding object in arr2
                    const obj2 = this.nodeDataCfgOld.find((item) => item.identifier === obj1.identifier);
                    
                    // If the object is found, compare the values
                    if (obj2) {
                        if (obj1.value !== obj2.value) {
                            const entry = parseNode(obj1, true);
                            saveArray.push(entry);
                        } else if ('plusminus' in obj2 && 'plusminus' in obj1) {
                            if (obj2.plusminus !== obj1.plusminus) {
                                const entry = parseNode(obj1, true);
                                saveArray.push(entry);
                            }
                        }
                    }
                    });
                }
                
                // console.log(saveArray);
                if (saveArray.length > 0) {
                    SocketioService.setOpcValue(saveArray, (err, response) => {
                        if (err) {
                            console.log(err);
                        } else {
                            console.log(response);
                            setOpcValueToast(response, this.$root);
                            this.dialogLoading = false;
                            if (diag === 'periodsHzg') this.showDiagHeating = false;
                            if (diag === 'periodsLft') this.showDiagVents = false;
                            if (diag === 'misc') this.showDiagMisc = false;
                            this.hideOP();
                        }
                    });
                } else {
                    if (diag === 'periodsHzg' && !this.periodCheckHzg) {
                        this.saveDiagNoChanges();
                        this.showDiagHeating = false;
                    } else if (diag === 'periodsHzg' && this.periodCheckHzg) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'periodsLft' && !this.periodCheckLft) {
                        this.saveDiagNoChanges();
                        this.showDiagVents = false;
                    } else if (diag === 'periodsLft' && this.periodCheckLft) {
                        this.saveDiagNoPeriods();
                    } else if (diag === 'misc') {
                        this.saveDiagNoChanges();
                        this.showDiagMisc = false;
                    }
                }
            }
        },
        saveDiagNoChanges() {
            setOpcValueToast(null, this.$root);
            this.dialogLoading = false;
            this.hideOP();
        },
        saveDiagNoPeriods() {
            this.$toast.add({
                severity: 'warn',
                summary: 'Eine Periode aktivieren!',
                detail: 'Es muss zumindest eine Periode aktiviert werden.',
                life: 3000,
            });
            this.dialogLoading = false;
            this.saveWait = false;
        },
        savePeriods(evnt, oldVals, callback) {
            this.dialogLoading = true;
            let childElements = [];
            console.log("OLDVALS: ", oldVals);
            console.log("SAVING > THIS.CURRENTGRP.PERIODS: ", this.currentGrp2);
            this.currentGrp2.forEach((element, index) => {
                const arrName = "Perioden_Heizung[" + index + "]"; 
                helpers.scaleVal2(oldVals[index], arrName);
                let arr = [];
                arr = helpers.scaleVal2(element, arrName, arr);
                // childElements = helpers.scaleVal(element, arrName, childElements);
                // const arr = helpers.scaleVal(element, false);
                console.log("Prepared Elm: ", arr);
                if (arr.length > 0) {
                    for (let i=0; i<arr.length; i++) {
                        // let oldVal = helpers.findByKey(this.periodsHeat[index], arr[i].key);
                        let oldVal = helpers.findByKey(oldVals[index], arr[i].key);
                        console.log("OLDVAL: ", oldVal.value);
                        console.log("NEWVAL: ", arr[i].value);
                        if (arr[i].value !== oldVal.value) {
                            if (!isNaN(arr[i].value)) {
                                childElements.push(arr[i]);
                            } else {
                                if (arr[i].unit === '#STRING') {
                                    childElements.push(arr[i]);
                                }
                            }
                        }
                    }
                    // childElements.push(arr);
                }
            });
            console.log("ChildElements: ", childElements);
            console.log("Prepared Group: ", this.currentGrp2);

            let childElements2 = [];
            // const oldVals2 = helpers.mapNodes(_.cloneDeep(this.nodeEntry.children), null);
            const oldVals2 = this.currentMap();
            console.log(">>> OLDVALS (MAP):", oldVals2);
            for (let [key, val] of this.node) {
                // console.log(">>> DA NODE EINTRAG:", key, val);
                if (val.value !== oldVals2.get(key).value && (val.type === "rw" || val.type === "write")) {
                    console.log(">>> CHANGE FOUND:", key, "OLDVAL:", oldVals2.get(key).value, "NEWVAL:", val.value, "VALTYPE:", val.type);
                    helpers.nodeScaler(val, key);
                    if (!isNaN(val.value)) {
                        childElements2.push(val);
                    } else {
                        if (val.unit === '#STRING') {
                            childElements2.push(val);
                        }
                    }
                }
            }

            let nodesToWrite = [];

            childElements2.forEach((element) => {
                // if (!isNaN(element.value.value)) {
                    const node = {
                        srv: this.nodeEntry.source.server,
                        // path: this.nodeEntry.source.path + '.' + element.displayName,
                        path: this.nodeEntry.source.path + '.' + element.key,
                        object: this.nodeEntry.source.path,
                        value: element,
                        datatype: 5,
                        arraytype: 0,
                        metadata: this.nodeEntry.metadata,
                        dataTypeIo: this.nodeEntry.value.datatype
                    }
                    nodesToWrite.push(node);
                // }
            });

            console.log(">>> NODES TO WRITE:", nodesToWrite);
            // > 0 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            if (nodesToWrite.length > 0) {
                SocketioService.setServerData('writeOpc', 'value', nodesToWrite, (err, response) => {
                    if (!err) {
                        if (response !== null) {
                            if (response === 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderung erfolgreich', detail: 'Wert wurde erfolgreich geändert', life: 1500 });
                            } else if (response > 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderungen erfolgreich', detail: response + ' Werte wurden erfolgreich geändert', life: 1500 });
                            } else {
                                this.$toast.add({ severity: 'info', summary: 'Änderung nicht notwendig', detail: 'Einstellwerte sind ident', life: 1500 });
                            }
                        }
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Fehler bei Änderung', detail: err + ' - ' + evnt, life: 1500 });
                    }
                    callback(true);
                });
            } else {
                callback(false);
            }
        },
        savePeriodsHeating(event) {
            const oldVals = JSON.parse(JSON.stringify(this.periodsHeat));
            this.savePeriods(event, oldVals, (response) => {
                if (response) {
                    this.showDiagHeating = false;
                    this.hideOP();
                    this.dialogLoading = false;
                } else {
                    this.showDiagHeating = false;
                    this.hideOP();
                    this.dialogLoading = false;
                }
            });
        },
        savePeriodsVents(event) {
            const oldVals = JSON.parse(JSON.stringify(this.periodsVent));
            this.savePeriods(event, oldVals, (response) => {
                if (response) {
                    this.showDiagVents = false;
                    this.hideOP();
                    this.dialogLoading = false;
                } else {
                    this.showDiagVents = false;
                    this.hideOP();
                    this.dialogLoading = false;
                }
            });    
        },
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            this.ioDialogData = JSON.parse(JSON.stringify(ioData));
            this.ioObj = JSON.parse(JSON.stringify(this.moduleData));
            this.entryDialog = true;
        },
        saveDialog() {
            this.submitted = true;
            if (this.ioDialogData.i) {
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
            else if (value === 'rhRel') this.showChartRhRel = true;
            else if (value === 'rhAbs') this.showChartRhAbs = true;
            else if (value === 'rhDef') this.showChartRhDefizit = true;
            else if (value === 'tempSetHzg') this.showChartTempSetHzg = true;
            else if (value === 'tempSetHzgAkt') this.showChartTempSetHzgAkt = true;
            else if (value === 'tempSetHzgLight') this.showChartTempSetHzgLight = true;
            else if (value === 'tempSetLft') this.showChartTempSetLft = true;
            else if (value === 'tempSetLftAkt') this.showChartTempSetLftAkt = true;
            else if (value === 'tempSetLftLight') this.showChartTempSetLftLight = true;
        },
        calcLux(val, mode) {
            return helpers.calcLight(val, mode);
        },
        focusDiagHzg(event) {
            this.periodDiagHzgDelay(event);
        },
        focusDiagLft(event) {
            this.periodDiagLftDelay(event);
        },
        periodDiagHzgDelay(event) {
            setTimeout(() => {
                this.diagHeatingPeriod = this.periodIndexHzg;
                this.focusElement(event);
            }, 300);
        },
        periodDiagLftDelay(event) {
            setTimeout(() => {
                this.diagVentsPeriod = this.periodIndexLft;
                this.focusElement(event);
            }, 300);
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.fade-in-enter-active {
    animation: fadeIn linear 2s;
}

.fade-in-leave-active {
    animation: fadeOut linear 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        
    }
    50% {
        // opacity: 1;
        background-color: rgba(200, 30, 0, 0.8);
    }
    100% {
        // opacity: 0;
    }
}

.alarm {
    animation: fadeInOut 1s infinite;
    -webkit-animation: fadeInOut 1s infinite;
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.p-togglebutton {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.2rem;
    margin: 0;
}
.nice-font {
  font-family: 'SensorFont';
  line-height: 1.3rem;
  position: relative;
}


@keyframes scroll-left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-200%);
  }
}
</style>